<template>
    <div class="view">
        <header>
            <i class="hwiconfont icon-fanhui" @click="close()"></i>
            <span>修改资料</span>
        </header>
        <div class="tx-box">
            <img @click="setHead()" class="tx" :src="rImg(user.head_portrait)" :onerror="txImg" alt="">
            <p class="des">点击修改头像</p>
        </div>
        <div class="input-box">
            <span class="name">昵称</span>
            <input class="input" v-model="user.name" type="text" placeholder="请输入昵称">
        </div>
        <div style="padding:0 15px;">
            <p class="name">个人介绍<span class="des" style="margin-left:5px" v-if="config.check_edit_slogan">[审核通过即可显示]</span></p>
            <textarea v-model="user.slogan" placeholder="介绍一下我自己" style="margin-top: 10px;height:150px ;" class="input" name="" id=""></textarea>
        </div>
        <div class="flex-c">
            <el-button class="btn" type="primary" round @click="submit()">确认</el-button>
        </div>
        <input type="file" @change="changeHead" ref="cover" style="display: none" accept="image/*" />
    </div>
</template>
<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    export default {
        components: {},
        data() {
            return {
                config: {},
                user: {}
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.config = this.$y_getKey('config');
        },
        mounted() {
            // 在组件被挂载之后调用。
            this.initData();
        },
        methods: {
            // ...mapMutations([])
            async initData() {
                this.user = await this.$y_getUser();
            },
            async submit() {
                if (!this.user.name) {
                    this.$y_msg('昵称不能为空');
                    return;
                }
                let {
                    msg
                } = await this.$y_ajax('api/user/editInfo', {
                    user_id: this.$y_getKey('userid'),
                    slogan: this.user.slogan,
                    name: this.user.name
                })
                this.$y_msg(msg);
            },
            setHead() {
                this.$refs.cover.click();
            },
            // 修改头像
            async changeHead() {
                let files = this.$refs.cover.files;
                let img = await this.$y_upImg(files[0]);
                if (!img) return;
                this.user.head_portrait = img;
                let {
                    msg
                } = await this.$y_ajax('api/user/editHead', {
                    user_id: this.$y_getKey('userid'),
                    head: img,
                    face: 0
                })
                this.$y_msg(msg)
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    .tx-box {
        text-align: center;
        padding: 30px 0;

        .tx {
            width: 80px;
            height: 80px;
            margin-bottom: 7px;
            border: 2px solid #FD5621;
        }
    }

    .des {
        font-size: 12px;
        color: #aaa;

    }

    .input {
        flex: 1;
        resize: none;
        background: #fafafa;
        padding: 7px 10px;
        line-height: 2;
        box-sizing: border-box;
        border-radius: 7px;
        width: 100%;
    }

    .input-box {
        padding: 15px;
        display: flex;
        align-items: center;

        .input {
            margin-left: 10px;
        }
    }



    .btn {
        width: 70vw;
        margin-top: 50px;
    }
</style>